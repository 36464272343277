.userProfileForm {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  background-color: #f8f9ff; /* Light contrasting background */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px;
}

.heading {
  text-align: center;
  margin-bottom: 20px;
  color: #444; /* Primary text color */
}

.section {  
  margin-bottom: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #c1c7f4; /* Light-colored line below each section heading */
}

.sectionHeading {
  margin-bottom: 10px;
  color: #555; /* Lighter text color for secondary text */
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.column {
  width: 48%; /* Each column takes up half of the row */
}

.columnFull {
  width: 100%; /* Full-width column */
}

.input, .textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  background-color: #f0f0f0; /* Input fields background */
  border: 1px solid #ddd;
  border-radius: 5px;
}

.textarea {
  height: 80px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap:20px;  
  margin-top: 20px;
}

.saveButton, .cancelButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #7b68ee; /* Button color */
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.saveButton:hover {
  background-color: #6a5acd; /* Hover color */
}

.cancelButton {
  background-color: #f44336; /* Red color for cancel */
}

.cancelButton:hover {
  background-color: #e53935;
}
