/* Container styling for the DataGrid */
.datagridContainer {
  width: 100%;
  margin: 0px auto;
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9); /* Evening Soft Gradient */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
  
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #444; /* Primary text color */
}

/* Pagination controls */
.paginationControls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginationButton {
  background-color: #7d4ea8; /* Medium dark purple */
  color: #f8f9ff; /* Light text color */
  border: none;
  padding: 8px 16px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease; /* Smooth transition */
}

.paginationButton:disabled {
  background-color: #ccc; /* Disabled state color */
  cursor: not-allowed; /* No pointer events */
}

.pageLabel {
  font-size: 16px;
  color: #444;
}
