.helpAndSupportPage {
    background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9);    
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #444;
    width:800px;
    height: 100%; /* Full height */
    max-height: 100vh;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contactSection {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #f8f9ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .contactSection h2 {
    color: #7b68ee;
    margin-bottom: 15px;
  }
  
  .uploadSection {
    padding: 20px;
    background-color: #f8f9ff;
    border-radius: 10px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
  
  .heading {
    color: #7b68ee;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5rem;
  }
  
  .uploadBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .uploadLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #7b68ee;
    color: white;
    padding: 15px 30px;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .uploadLabel:hover {
    background-color: #6a5acd;
  }
  
  .uploadIcon {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .uploadText {
    font-size: 1.1rem;
  }
  
  .busyAnimation {
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    color: #555;
  }
  
  .loader {
    width: 25px;
    height: 25px;
    border: 4px solid #ccc;
    border-top: 4px solid #7b68ee;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .uploadSuccessMessage {
    margin-top: 20px;
    padding: 15px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
    border-radius: 6px;
    display: flex;
    align-items: center;
  }
  
  .successIcon {
    font-size: 1.5rem;
    margin-right: 10px;
  }

  .searchButton {
    padding: 10px 20px;
    background-color: #7b68ee;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .searchButton:hover {
      background-color: #6a5acd;
  }
  