/* Layout.module.css */

/* Base styles for desktop and larger screens */
.layout {
  display: flex;
  flex-direction: column; /* Main layout is column-based */
  min-height: 100vh; /* Full viewport height */
}

.mainContainer {
  display: flex; /* Flexbox container for sidebar and content */
  flex-direction: row; /* Side-by-side layout */
  margin-top: 60px; /* Height of the navbar */
  width: 100%; /* Full width to stretch the entire page */
  flex-grow: 1; /* Allow the main container to grow */
}

/* Adjust .content to fill remaining space after sidebar */
.content {
  padding: 20px; /* Padding for inner content */
  flex-grow: 1; /* Allow .content to take remaining space */
  height: 100%; /* Full height */
  overflow-y: auto; /* Scrollable content */
  overflow-x: hidden; /* Prevent horizontal scroll */
  color: #FFF; /* Primary text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9); /* Gradient background */
  margin-left: 80px; /* Ensure content is beside collapsed sidebar */
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Mobile responsiveness */
/* For screens smaller than 768px (tablet and mobile devices) */
@media (max-width: 768px) {
  .mainContainer {
    flex-direction: column; /* Stack sidebar and content vertically */
    margin-left: 0; /* Remove the margin for the sidebar */
  }

  .content {
    margin-left: 0; /* No margin in mobile view */
    width: 100%; /* Full width on mobile */
    padding: 15px; /* Adjust padding for smaller screens */
  }
}

/* For screens smaller than 480px (mobile devices) */
@media (max-width: 480px) {
  .content {
    padding: 10px; /* Less padding on smaller screens */
    gap: 5px; /* Less gap between items */
  }

  .mainContainer {
    margin-top: 50px; /* Adjust navbar margin for smaller devices */
  }
}
