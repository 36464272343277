/* LoginForm.module.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
  background: linear-gradient(to bottom, #e0e4fc, #c1c7f4, #a3aad9);
}

.container {
  display: flex; /* Flexbox for two-column layout */
  min-height: 100vh; /* Full viewport height */
  width: 100vw; /* Full width of the viewport */
  margin: 0; /* Remove default margin */
}

.leftSection {
  flex: 1; /* Take up half of the width */
  padding: 40px;
  background-color: #00508d; /* Theme primary color */
  color: white; /* Text color */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.navbar {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.topLogo {
  height: 50px; /* Logo size */
  margin-right: 10px; /* Space between logo and brand name */
}

.brandName {
  font-size: 1.8em; /* Adjust font size for brand name */
  font-weight: bold; /* Bold text */

}

.content {
  margin-top: 0px; /* Margin from top */
}

.logoContainer{  
  display: flex;
  justify-content: center;
}

.logoContainer img{
  width:250px;
}

.heading {
  font-size: 1.6em; /* Heading font size */
  margin-bottom: 10px; /* Space below the heading */
  color:#FFF
}

.bulletPoints {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove padding */
  margin: 0 0 20px; /* Margin below */
}

.bulletPoints li {
  margin-bottom: 10px; /* Space between bullet points */
  font-size: 1.1em; /* Slightly larger text */
  line-height: 1.5; /* Line height for readability */
  color: #e0e4fc; /* Lighter color for bullet points to match background */
  position: relative; /* For positioning the arrow */
  padding-left: 25px; /* Space for the arrow */
}

.bulletPoints li::before {
  content: '➤'; /* Arrow symbol */
  color: #f8f9ff; /* Light contrasting color for the arrow */
  position: absolute;
  left: 0; /* Position the arrow to the left */
  top: 0; /* Align with the top of the list item */
  font-size: 1.2em; /* Slightly larger arrow */
}
.rightSection {
  flex: 1; /* Take up half of the width */
  padding: 60px; /* Padding around the SSO container */
  background-color: #f8f9ff; /* Light contrasting background */
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box; /* Ensure padding does not affect width */
}

.ssoContainer {
  text-align: center; /* Center align SSO container */
}

.ssoHeading {
  font-size: 1.8em; /* Heading font size */
  margin-bottom: 20px; /* Space below heading */
  color: #444; /* Text color */
}

.ssoText {
  margin-bottom: 30px; /* Margin below text */
}

.ssoButton {
  display: block; /* Block display */
  width: 100%; /* Full width */
  padding: 12px; /* Padding */
  background-color: #00508d; /* Theme primary color */
  color: white; /* Button text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1em; /* Font size */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.ssoButton:hover {
  background-color: #7b68ee; /* Hover color */
}

/* MFA Popup Styles */
.mfaPopup {
  position: fixed; /* Fixed position */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex; /* Center content */
  align-items: center;
  justify-content: center;
  z-index: 1000; /* On top of other elements */
}

.mfaContent {
  background-color: white; /* Popup background */
  padding: 30px; /* Padding inside the popup */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  text-align: center; /* Center text */
  width: 300px; /* Fixed width */
}

.mfaInput {
  width: 100%; /* Full width input */
  padding: 10px; /* Padding for input */
  margin-bottom: 20px; /* Margin below input */
  border: 1px solid #ccc; /* Input border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1em; /* Font size */
}

.mfaSubmitButton {
  width: 100%; /* Full width button */
  padding: 12px; /* Padding */
  background-color: #6a5acd; /* Theme primary color */
  color: white; /* Button text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  font-size: 1em; /* Font size */
  cursor: pointer; /* Pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.mfaSubmitButton:hover {
  background-color: #7b68ee; /* Hover color */
}
