.feedbackFormContainer {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .formHeading {
    font-size: 24px;
    font-weight: bold;
    color: #444;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formGroup label {
    font-size: 16px;
    font-weight: 500;
    color: #444;
    display: block;
    margin-bottom: 8px;
  }
  
  .emojiRating {
    margin: 0 auto;
    width:80%;
    display: flex;
    justify-content: space-between;
  }
  
  .emoji {
    font-size: 30px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .emoji:hover {
    transform: scale(1.2);
  }
  
  .emoji.active {
    transform: scale(1.5);
  }
  
  .emoji:nth-child(1) {
    color: red;
  }
  
  .emoji:nth-child(2) {
    color: orange;
  }
  
  .emoji:nth-child(3) {
    color: yellow;
  }
  
  .emoji:nth-child(4) {
    color: lightgreen;
  }
  
  .emoji:nth-child(5) {
    color: green;
  }
  
  .textArea {
    width: 95%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f0f0f0;
  }
  
  .radioGroup {
    display: flex;
    justify-content: space-between;
    width: 150px;
  }
  
  .submitButton {
    width: 100%;
    padding: 12px;
    background-color: #7b68ee;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #6b58dd;
  }
  
  .thankYouMessage {
    text-align: center;
  }
  
  .thankYouMessage h2 {
    font-size: 22px;
    color: #444;
  }
  