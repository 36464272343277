.consultantForm {
  padding: 20px;
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mainHeading {
  font-size: 2em;
  color: #4b0082;
  margin-bottom: 20px;
  text-align: center;
}

.section {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f8f9ff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.subheading {
  font-size: 1.5em;
  color: #4b0082;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.formRow {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.formGroup {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formGroup label {
  margin-bottom: 5px;
  color: #444;
}

label {
  color: black;
}

.textInput {
  width: 90%;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.textArea {
  width: 90%;
  height: 30px;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  resize: vertical;
}

.buttonGroup {
  display: flex;
  gap: 20px;
  margin: 20px auto;
  justify-content: center;
}

.btn {
  padding: 10px 20px;
  background-color: #4b0082;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.btn:hover {
  background-color: #7b68ee;
  transform: translateY(-2px);
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.visitDate {
  color: #000;
}

.galleryItem {
  flex: 1 1 calc(25% - 10px);
  background-color: #f8f9ff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: small;
}

.galleryItem:hover {
  transform: scale(1.05);
}

.galleryItem img {
  width: 100px;
  height: 200px;
  display: block;
  border-radius: 5px;
}

.subheading {
  font-size: 1.5em;
  color: #4b0082;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.testGroup {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.testList {
  display: flex;
  flex-direction: column;
}

.recommendationDropdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.dropdown {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 16px;
  color: #444;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .formRow {
    flex-direction: column; /* Stack form rows on smaller screens */
  }

  .galleryItem {
    flex: 1 1 calc(50% - 10px); /* Two items per row for tablets */
  }

  .mainHeading {
    font-size: 1.75em; /* Adjust heading size for smaller screens */
  }

  .subheading {
    font-size: 1.25em;
  }

  .btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .galleryItem {
    flex: 1 1 calc(100% - 10px); /* Stack gallery items for small mobile screens */
  }

  .textInput, .textArea {
    width: 100%; /* Ensure inputs take full width on mobile */
  }

  .btn {
    width: 100%; /* Buttons take full width on mobile */
    padding: 10px;
  }

  .mainHeading {
    font-size: 1.5em; /* Smaller font size for mobile */
  }

  .subheading {
    font-size: 1.2em;
  }
}
