/* Ensure the form container takes full width and has a distinct background */
.registrationContainer {
  width: 100%;
  max-width: 100%;
  padding: 20px;
  margin: 0;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #e0e4fc, #c1c7f4, #a3aad9);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* Section container using grid */
.formSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

/* Form groups for input fields */
.formGroup {
  display: flex;
  flex-direction: column;
}

/* Container styling for the DataGrid */
.datagridContainer {
  width: 100%;
  margin: 0px auto;
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9);
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #444;
}

/* Custom styles for the DataGrid */
.customGrid {
  border: none;
  border-radius: 12px;
  background-color: #f8f9ff;
}

/* Responsive grid layout for smaller devices */
@media (max-width: 768px) {
  .formSection {
    grid-template-columns: 1fr;
  }

  .formInput, select {
    font-size: 12px;
  }

  .formButton {
    width: 100%;
    font-size: 12px;
  }

  h2 {
    font-size: 20px;
  }

  .registrationContainer {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .formSection {
    gap: 10px;
  }

  .formInput, select {
    font-size: 12px;
    padding: 6px 8px;
  }

  .formButton {
    width: 100%;
    height: 36px;
    font-size: 12px;
    padding: 6px 10px;
  }

  .sectionHeading, .formHeading {
    font-size: 18px;
  }

  h2 {
    font-size: 18px;
  }

  .datagridContainer {
    padding: 10px;
  }
}

/* Row styling for alternating row colors */
.customGrid .rdgRow:nth-child(odd) {
  background-color: #f0f0f0;
}

.customGrid .rdgRow:nth-child(even) {
  background-color: #f8f9ff;
}

/* Labels for input fields */
.formLabel {
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
}

/* Input fields and dropdown styling */
.formInput, select {
  width: 100%;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  font-size: 14px;
  color: #444;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 10px;
}

/* Button styling */
.formButton {
  width: 80px;
  background-color: #7b68ee;
  color: #fff;
  padding: 8px 12px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
}

.formButton:hover {
  background-color: #6a5acd;
}

.inlineButtonGroup {
  display: flex;
  gap: 10px;
  align-items: center;
}

.formHeaderContainer {
  grid-column: span 2;
  text-align: left;
}

.formHeading {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

.sectionHeading {
  color: #000;
}

.sectionLine {
  border: none;
  height: 1px;
  background-color: #94a0e2;
  width: 100%;
  margin: 10px auto;
}

.mainHeading {
  display: flex;
  justify-content: center;
}
