.layoutContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9); /* Evening Soft Gradient Theme */
}

.navbar {
  position: fixed; /* Fixes navbar at the top */
  top: 0;
  left: 0;
  width: 100%; /* Full width */
  z-index: 1000; /* Ensures it stays above other content */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 60px; /* Set height to 60px */
  background-color: #00508d; /* Medium dark purple */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoImage {
  height: 40px; /* Logo size */
  margin-right: 10px; /* Space between logo and text */
}

.brandNameContainer {
  display: flex;
  flex-direction: column; /* Arrange title and subtitle vertically */
  align-items: flex-start; /* Align text to the left */
}

.brandNameTitle {
  font-size: 22px; /* Bigger font for main title */
  font-weight: bold;
  color: #ffffff; /* White color for better contrast */
}

.brandNameSubtitle {
  font-size: 12px; /* Smaller font for subtitle */
  color: #dddddd; /* Slightly lighter color for subtitle */
  margin-top: -5px; /* Adjust spacing between title and subtitle */
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 60px; /* Push content below navbar */
  background-color: rgba(248, 249, 255, 0.9); /* Background overlay for content */
}

.backButton {
  background-color: #00508d;
  color: #FFFFFF;
  padding: 8px 16px;
  border: 2px solid #7b68ee;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-right: 50px; /* Space after the button */
}

.backButton:hover {
  background-color: #ffffff;
  color: #7b68ee;
}
