.billPreviewContainer {
    width: 100%;    
    background: linear-gradient(to bottom, #e0e4fc, #c1c7f4, #a3aad9); /* Matches the theme */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between elements */    
  }
  
  .header {
    font-size: 1.8em;
    color: #4b0082;    
    text-align: center;
  }
  
  .billImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f8f9ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .billImage {
    width: 100%; /* Adjust width based on the container */
    max-width: 500px; /* Limit maximum width */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .noBillMessage {
    color: #666;
    font-size: 1.2em;
    text-align: center;
    padding: 20px;
    background-color: #f8f9ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    width:90%;
  }
  
  .printButton {
    position: absolute; /* Position the button absolutely */
    top: 20px; /* Adjust the top position */
    right: 20px; /* Adjust the right position */
    padding: 0.5em 1em; /* Padding for the button */
    background-color: #7b68ee; /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .printButton:hover {
    background-color: #6a5acd; /* Hover effect */
  }
  
  /* Ensure the parent container is positioned relative */
  .billPreviewContainer {
    position: relative; /* Ensure it is positioned relative to position the button */
    width: 100%;    
    background: linear-gradient(to bottom, #e0e4fc, #c1c7f4, #a3aad9); /* Matches the theme */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between elements */
  }
  