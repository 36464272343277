/* Sidebar.module.css */
.sidebar {
  width: 250px; /* Full width when expanded */
  background-color: #f8f9fa; /* Light background */
  padding: 20px 0;
  height: 100vh;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  position: fixed; /* Fixed positioning */
  left: 0; /* Attach to the left end */
  transition: width 0.3s ease; /* Smooth transition for width */
  overflow: hidden; /* Hide overflow */
  z-index: 900; /* Stays under the navbar */

  /* display: flex; /* Use flexbox to manage positioning */
  /* flex-direction: column; /* Arrange children vertically */
  /* justify-content: space-between;  */
}

.collapsed {
  width: 80px; /* Collapsed width to show only icons */
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bottomMenu {  
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: auto; /* Push to the bottom */
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.menuItem:hover {
  background-color: #e0e0e0; /* Hover background color */
}

.menuLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #444; /* Primary text color */
}

.icon {
  font-size: 20px; /* Icon size */
  color: #573b8a; /* Primary color for icons */
  margin-right: 15px; /* Space between icon and label */
}

.label {
  font-size: 16px;
  color: #333; /* Text color */
  transition: opacity 0.3s ease; /* Smooth transition for label */
  white-space: nowrap; /* Prevent text wrap */
}

.collapsed .label {
  opacity: 0; /* Hide text in collapsed state */
  margin-left: -50px; /* Negative margin to hide text */
  pointer-events: none; /* Disable interactions */
}

.collapsed .icon {
  margin-right: 0; /* Remove margin in collapsed state */
}
