/* Navbar.module.css */
.navbar {
    position: fixed; /* Fixes navbar at the top */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    z-index: 1000; /* Ensures it stays above other content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    height: 60px; /* Set height to 80px */
    background-color: #00508d; /* Darker background color */
    color: #fff; /* Lighter text color for contrast */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .logoContainer {
    display: flex;
    align-items: center;
  }
  
  .logoImage {
    height: 40px; /* Logo size */
    margin-right: 10px; /* Space between logo and text */
  }
  
  .brandNameContainer {
    display: flex;
    flex-direction: column; /* Arrange title and subtitle vertically */
    align-items: flex-start; /* Align text to the left */
  }
  
  .brandNameTitle {
    font-size: 22px; /* Bigger font for main title */
    font-weight: bold;
    color: #ffffff; /* White color for better contrast */
  }
  
  .brandNameSubtitle {
    font-size: 12px; /* Smaller font for subtitle */
    color: #dddddd; /* Slightly lighter color for subtitle */
    margin-top: -5px; /* Adjust spacing between title and subtitle */
  }
  
  .userMenu {
    position: relative; /* Position relative for dropdown */
    cursor: pointer;
    display: flex;
    align-items: center; /* Align items vertically */
    margin-right: 60px; /* Move the user menu 100px to the left */
  }

  .userMenuButton {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .userIcon {
    font-size: 24px; /* User icon size */
    color: #fff; /* Icon color */
    margin-right: 8px; /* Spacing between icon and text */
  }
  
  .userMenuText {
    font-size: 16px; /* Text size */
    color: #fff; /* Text color */
  }
  
  .dropdownMenu {
    position: absolute;
    right: 0;
    top: 40px; /* Adjust dropdown position */
    background-color: #ffffff; /* White background for dropdown */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    border-radius: 5px;
    overflow: hidden;
    width: 180px; /* Dropdown width */
    z-index: 1100; /* Above other elements */
    display: block; /* Ensure it is displayed when open */
  }
  
  .menuItem {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #444; /* Primary text color */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .menuItem:hover {
    background-color: #f0f0f0; /* Hover background color */
  }
  
  .menuIcon {
    margin-right: 10px; /* Space between icon and text */
    font-size: 16px; /* Icon size */
    color: #573b8a; /* Primary color for icons */
  }
  