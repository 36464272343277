.container{
    display: flex;
    flex-direction: row;
}
.leftSection{
    width:50%
}

.rightSection{
    width:50%
}