.prescriptionPreview {
    width: 100%;
    margin: 0px auto;
    background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9); /* Evening Soft Gradient */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Space between elements */
  }
  
  .header {
    font-size: 1.8em;
    color: #4b0082;
    margin-bottom: 20px;
    text-align: center;
  }

  .hiddenFileInput {
    display: none; /* Completely hides the file input */
  }
  
  .contentArea {
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f8f9ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    min-height: 550px; /* Ensures a minimum height for the area */
    display: flex;
    align-items: center;
    justify-content: center;    
  }
  
  .uploadContainer {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center elements horizontally */
    justify-content: center; /* Center elements vertically */
    padding: 20px;
    width: 100%;  
    height: 600px;  
    border-radius: 8px;
    background-color: #f8f9ff; /* Light background for contrast */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
    border: 2px dashed #4b0082; /* Dashed border with theme color */
  }
    
  .uploadLabel {
    font-size: 1.2em;
    color: #4b0082; /* Themed text color */
    margin-bottom: 10px; /* Spacing below label */
    text-align: center; /* Center the label text */
  }
  
  .uploadInput {
    margin: 10px 0; /* Spacing above and below input */
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .uploadButton {
    padding: 10px 20px;
    background-color: #4b0082; /* Matching the themed button color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin-top: 10px; /* Margin above button */
  }
  
  .uploadButton:hover {
    background-color: #7b68ee; /* Lighter variant for hover effect */
    transform: translateY(-2px);
  }
  
  
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  .previewImage {
    width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .noPreview {
    text-align: center;
    color: #666;
    font-size: 1.2em;
  }
  
  .textAreaContainer {
    width: 90%;
    background-color: #f8f9ff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-top: 20px;
  }
  
  .textArea {
    width: 95%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #444;
    resize: vertical; /* Allows resizing vertically */
  }
  
  .buttonGroup {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    background-color: #4b0082;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn:hover {
    background-color: #7b68ee;
    transform: translateY(-2px);
  }
  