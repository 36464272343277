.billingForm {
  width: 100%;
  max-width: 100vw;
  padding: 1em;
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9);
  box-sizing: border-box;
  border-radius: 0.8em;
  box-shadow: 0 0.4em 0.8em rgba(0, 0, 0, 0.1);
}

/* Patient Details Section */
.patientDetails {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* Row for Input Fields */
.row {
  display: flex;
  gap: 1em;
  flex-wrap: wrap; /* Allow wrapping for small screens */
}

/* Patient Field for Labels and Inputs */
.patientField {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 8em;
}

.patientField label {
  width: 6em;
  font-weight: bold;
  color: #444;
  margin-right: 0.5em;
  font-size: 0.9em; /* Reduced font size */
}

/* Input Fields for Text and Date */
.patientField input[type='text'],
.patientField input[type='date'] {
  flex: 1;
  padding: 0.4em;
  background-color: #f0f0f0;
  color: #555;
  border: 0.1em solid #ccc;
  border-radius: 0.4em;
  box-sizing: border-box;
  font-size: 0.9em; /* Reduced font size */
}

/* Search Button Styling */
.searchButton {
  margin-left: 0.5em;
  padding: 0.5em 1em;
  background-color: #7b68ee;
  color: white;
  border: none;
  border-radius: 0.4em;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9em; /* Reduced font size */
}

.searchButton:hover {
  background-color: #6a5acd;
}

/* Billing Inputs Section */
.billingInputs {
  margin-top: 2em;
}

.billingInputs h3 {
  margin-bottom: 0.8em;
  color: #444;
  font-size: 1.1em; /* Reduced font size */
}

/* Billing Input Row */
.billingInputRow {
  display: flex;
  gap: 1em;
  flex-wrap: wrap; /* Allow wrapping for small screens */
  margin-bottom: 1em;
  overflow-x: auto;
}

/* Billing Input Fields */
.billingInputField {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 6em;
}

.billingInputField label {
  margin-bottom: 0.4em;
  font-weight: bold;
  color: #444;
  font-size: 0.9em; /* Reduced font size */
}

/* Input Field Styling */
.inputField {
  padding: 0.4em;
  background-color: #f0f0f0;
  color: #555;
  border: 0.1em solid #ccc;
  border-radius: 0.4em;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9em; /* Reduced font size */
}

/* Button Group Styling */
.buttonGroup {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.addItemButton,
.submitButton {
  padding: 0.5em 1em;
  background-color: #7b68ee;
  color: white;
  border: none;
  border-radius: 0.4em;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9em; /* Reduced font size */
  box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.1);
}

.addItemButton:hover,
.submitButton:hover {
  background-color: #6a5acd;
}

/* Added Items Section */
.addedItems {
  margin-top: 1em;
  background-color: #f8f9ff;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.05);
}

.addedItem {
  margin-bottom: 1em;
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
}

.billingItemsGrid {
  width: 95%;
}

/* Delete Button Styling */
.deleteButton {
  padding: 0.4em 0.8em;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 0.4em;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.9em; /* Reduced font size */
}

.deleteButton:hover {
  background-color: #e60000;
}

/* DataGrid Styling */
.dataGrid {
  border: none;
  font-size: 0.9em;
  color: #444;
  background-color: #f8f9ff;
  border-radius: 0.4em;
  box-shadow: 0 0.2em 0.4em rgba(0, 0, 0, 0.05);
}

/* Responsive Adjustments for Smaller Screens */
@media (max-width: 768px) {
  .row {
      flex-direction: column; /* Stack fields vertically on smaller screens */
  }

  .buttonGroup {
      flex-direction: column; /* Stack buttons vertically on smaller screens */
  }

  .billingInputRow {
      flex-direction: column; /* Stack billing inputs vertically */
  }

  .addedItem {
      font-size: 0.8em; /* Smaller font size for added items */
  }
}

@media (max-width: 480px) {
  .billingForm {
      padding: 0.8em; /* Reduce padding on mobile screens */
  }

  .inputField, .submitButton, .addItemButton {
      padding: 0.4em 0.8em; /* Smaller padding for inputs and buttons */
  }

  .patientField label {
      width: 5em; /* Reduce label width for mobile */
  }

  .billingInputField label {
      font-size: 0.8em; /* Smaller font size for labels on mobile */
  }

  .billingInputField, .patientField {
      min-width: 100%; /* Take full width on mobile */
  }

  .billingItemsGrid {
      width: 100%; /* Take full width for the grid on mobile */
  }

  .row {
      flex-direction: column; /* Stack inputs vertically */
  }

  .addedItems {
      padding: 0.5em; /* Reduce padding for added items section */
  }
}
