/* PatientSearch.module.css */
.container {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
}

.searchSection {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.searchInput {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
    font-size: 16px;
}

.searchButton {
    padding: 10px 20px;
    background-color: #7b68ee;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.searchButton:hover {
    background-color: #6a5acd;
}

.resultSection {
    text-align: center;
    margin-top: 20px;
    object-fit: cover;
}

.medicalRecordImage {
    max-width: 100%;    
    height: auto;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.errorMessage {
    color: #d9534f;
    text-align: center;
    margin-top: 10px;
}
