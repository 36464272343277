/* Dashboard.module.css */
.dashboardContainer {
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9);
  padding: 20px;
  box-sizing: border-box;
}

.appBar {
  background-color: #7b68ee;
  box-shadow: none;
}

.title {
  flex-grow: 1;
}

.content {
  margin-top: 20px;
}

/* Card Styles */
.card {
  background-color: #f8f9ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
}

/* Responsive chart and datagrid containers */
.chartContainer {
  height: 300px;
  width: 100%;
  margin-top: 20px;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .dashboardContainer {
    padding: 10px;
  }

  .content {
    margin-top: 10px;
  }

  .card {
    padding: 10px;
  }

  .chartContainer {
    height: 250px;
    margin-top: 15px;
  }

  .dataGridContainer {
    height: 250px;
    margin-top: 15px;
  }
}

@media screen and (max-width: 480px) {
  .appBar {
    padding: 10px;
  }

  .title {
    font-size: 18px;
  }

  .card {
    padding: 8px;
  }

  .chartContainer {
    height: 200px;
  }

  .dataGridContainer {
    height: 200px;
  }
}

.buttonGroup {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
}

.alertSection {
  margin-top: 20px;
}
