/* AppointmentForm.module.css */
.appointmentForm {
    width:100%;
    max-width:95%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: linear-gradient(to right, #e0e4fc, #c1c7f4, #a3aad9); /* Themed background */    
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);    
    margin: 0 auto;
    padding:10px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input, select, textarea {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 80%;
    margin-bottom: 10px;
  }
  
  textarea {
    resize: vertical;
  }
  
  .patientSearch {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .patientName {
    margin-bottom: 15px;
    font-weight: bold;
    color: #444;
  }

  .inlineContainer {
    display: flex;
    gap: 20px; /* Space between the fields */
    margin-bottom: 15px; /* Space below the container */
  }
  
  .inlineField {
    flex: 1; /* Distribute space evenly between the fields */
    display: flex;
    flex-direction: column;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-between;
  }
  
   .formButton {
    padding: 10px 15px;
    border: none;
    background-color: #7b68ee;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .formButton:hover {
    background-color: #6a5acd;
  }

  .appCalender{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  